<script>
export default {
  name: 'printTemplate',
  props: {
    config: {
      type: Object,
      default: ()=>({
        printId: 'printTemplate',
        contentConfig: [],
      }),
      required: true,
    },
  },
  render (h) {
    const renderItems = [];
    const actions = [];

    if (this.config.logo) {
      actions.push(h(
        'img',
        {
          attrs: {
            src: this.config.logo,
          },
          style: {
            width: '100px',
          },
        },
      ));
    }

    if (this.config.title) {
      actions.push(h(
        'div',
        {
          style: {
            fontSize: '20px',
            fontWeight: 'bold',
            textAlign: 'center',
            fontFamily: 'SimSun, serif',
            marginBottom: '40px',
            color: '#000',
          },
        },
        this.config.title,
      ));
    }

    if (this.config.creationTime) {
      actions.push(h(
        'div',
        {
          style: {
            fontSize: '16px',
            textAlign: 'right',
            fontFamily: 'SimSun, serif',
            color: '#000',
          },
        },
        `日期：${this.config.creationTime}`,
      ));
    }

    this.config.contentConfig.forEach(content => {
      if (content.type === 'table') {
        const trItems = [];

        const averageWidth = (100 / content.colCount).toFixed(2);

        if (content.rows && content.rows.length) {
          content.rows.forEach(row => {
            const tdItems = [];
            if (row.cols && row.cols.length) {
              row.cols.forEach(col => {
                const divs = [];
                const style = col.style ? {
                  padding: '4px 3px',
                  color: '#000',
                  fontSize: '16px',
                  ...col.style,
                } : {
                  padding: '4px 3px',
                  color: '#000',
                  fontSize: '16px',
                };

                if (col.type === 'footer') {
                  const subDivs = [];

                  col.value.forEach(val => {
                    const label = h(
                      'div',
                      {
                        style: val.labelStyle || {},
                      },
                      val.label,
                    );

                    const content = h(
                      'div',
                      {
                        style: val.contentStyle || {},
                      },
                      val.content,
                    );

                    subDivs.push(
                      h(
                        'div',
                        {
                          style: {
                            display: 'flex',
                            marginBottom: '1em',
                          },
                        },
                        [label, content]),
                    );
                  });

                  divs.push(h(
                    'div',
                    {
                      style,
                    },
                    subDivs,
                  ));
                } else if (Array.isArray(col.value)) {
                  col.value.forEach(val => {
                    divs.push(h(
                      'div',
                      {
                        style,
                      },
                      val,
                    ));
                  });
                } else {
                  if (/\r\n/.test(col.value)) {
                    let vals = col.value.split('\r\n');
                    const spans = [];

                    vals.forEach(val => {
                      spans.push(h(
                        'span',
                        {},
                        val,
                      ));

                      spans.push(h(
                        'br',
                      ));
                    });

                    divs.push(h(
                      'div',
                      {
                        style,
                      },
                      spans,
                    ));
                  } else {
                    divs.push(h(
                      'div',
                      {
                        style,
                      },
                      col.value,
                    ));
                  }
                }

                const tdStyle = col.tdStyle ? {
                  ...col.tdStyle,
                  width: col.colSpan !== undefined ? (col.colSpan * averageWidth) + '%' : (averageWidth + '%'),
                } : {
                  width: col.colSpan !== undefined ? (col.colSpan * averageWidth) + '%' : (averageWidth + '%'),
                };

                tdItems.push(h(
                  'td',
                  {
                    attrs: {
                      colSpan: col.colSpan !== undefined ? col.colSpan : 1,
                      rowSpan: col.rowSpan !== undefined ? col.rowSpan : 1,
                    },
                    style: tdStyle,
                  },
                  divs,
                ));
              });

              trItems.push(h(
                'tr',
                {},
                tdItems,
              ));
            }
          });
        }

        actions.push(h(
          'table',
          {
            attrs: {
              border: '1',
              width: '100%',
            },
            style: {
              borderCollapse: 'collapse',
            },
          },
          trItems,
        ));
      } else if (content.type === 'attachment' && (content.images?.length || content.files?.length)) {
        const trItems = [
          h(
            'tr',
            {},
            [
              h(
                'td',
                {
                  style: {
                    fontWeight: 'bold',
                  },
                },
                [
                  h(
                    'div',
                    {
                      style: {
                        width: '48px',
                        padding: '4px 3px',
                      },
                    },
                    '附件',
                  ),
                ],
              ),
            ],
          ),
        ];

        if (content.images?.length) {
          const images = content.images.map(image => {
            return h(
              'img',
              {
                style: {
                  width: '100px',
                  height: '100px',
                  marginLeft: '10px',
                  marginTop: '10px',
                },
                attrs: {
                  src: image.url,
                },
              },
            );
          });

          trItems.push(h(
            'tr',
            {},
            [
              h('td',
                {},
                [
                  h(
                    'div',
                    {
                      style: {
                        width: '48px',
                        padding: '4px 3px',
                      },
                    },
                    '图片',
                  ),
                ],
              ),
              h(
                'td',
                {},
                [h('div',
                  {
                    style: {
                      paddingBottom: '10px',
                      padding: '4px 3px',
                    },
                  },
                  images),
                ],
              ),
            ],
          ));
        }

        if (content.files?.length) {
          const files = [];
          content.files.forEach(file => {
            if (file[content.mapNameKey] || file.url) {
              let fileName = file[content.mapNameKey];

              if (!fileName) {
                const urlSplitArr = file.url.split('/');

                fileName = urlSplitArr[urlSplitArr.length - 1];
              }

              files.push(h(
                'div',
                {},
                fileName,
              ));
            }
          });

          trItems.push(h(
            'tr',
            {},
            [
              h('td',
                {},
                [
                  h(
                    'div',
                    {
                      style: {
                        width: '48px',
                        padding: '4px 3px',
                      },
                    },
                    '文件',
                  ),
                ],
              ),
              h(
                'td',
                {},
                [
                  h(
                    'div',
                    {
                      style: {
                        padding: '6px',
                      },
                    },
                    files,
                  ),
                ],
              ),
            ],
          ));
        }

        actions.push(h(
          'table',
          {
            attrs: {
              border: '1',
              width: '100%',
            },
            style: {
              marginTop: '60px',
              borderCollapse: 'collapse',
            },
          },
          trItems,
        ));
      }
    });

    renderItems.push(h(
      'div',
      {
        attrs: {
          id: this.config.printId,
        },
      },
      actions,
    ));

    return renderItems;
  },
};
</script>
